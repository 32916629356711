// $height-A5: calc(8.5in);
// $width-A5: calc(11in);
$height-A5: 297mm;
$width-A5: 182mm;

// $height-A5: calc(210mm);
// $width-A5: calc(148mm);

// $height-A5: 8.27in;
// $width-A5: 5.83in;

$height-footer: 100px;

.tag-print__container {
  width: $height-A5;
  height: $width-A5;
  background-color: white;
  display: flex;
  max-width: 100vw;
  // padding: 10px;
  //   border: 1px solid #eeeeee;
  //   transform: rotate(-90deg);
}

.tag-print__item-container {
  // background-color: yellow;
  width: 70mm;
  height: 130mm;
  padding: 5mm;
}
.tag-print__image {
  // background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .tag-print__image-qr-container {
    position: relative;
  }
  .tag-print__image-brand {
    position: absolute;
    width: 10mm;
    height: 10mm;
    background-color: white !important;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, -50%);
    border-radius: 1mm;
    border: 1px solid white;
  }
}
.tag-print__info {
  margin-top: 2rem;
  text-align: center;
  // background-color: green;
}
.tag-print__info-product-name {
  font-size: 18px;
  font-weight: 700;
}
.tag-print__info-product-size {
  font-size: 18px !important;
}

@media print {
  html,
  body {
    height: auto;
  }

  .noPrintClass {
    display: none;
  }

  .printClass {
    display: flex;
  }

  .printClass:last-child {
    page-break-after: auto;
  }

  .tag-print__container {
    // height: 210mm !important;
    overflow: hidden;
    page-break-after: always;
    height: 100vh !important;
  }

  body.sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .right-side,
  .sidebar-mini.sidebar-collapse .main-footer {
    margin-left: 0px !important;
  }

  .content {
    padding: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.print-placeholder {
  &:after {
    content: "...........................................................................................................................";
    display: inline-block;
  }
  &.has-text {
    position: relative;
    padding-bottom: 3px;
    &:after {
      left: 0;
      bottom: -5px;
      position: absolute;
    }
  }
}

.print-size-checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M1 5.917 5.724 10.5 15 1.5'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 0.65em 0.65em;
  -webkit-print-color-adjust: exact;
  position: relative;
  top: -2px;
  left: 1px;
}
