@import url("https://fonts.googleapis.com/css?family=Prompt");
@import url("https://fonts.googleapis.com/css2?family=Poppins");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  font-family: var(--appFont);
}
.body-en *:not(i) {
  font-family: "Poppins", "sans-serif";
}
.body-th *:not(i) {
  font-family: "Prompt", "sans-serif";
}
.font-en {
  font-family: "Poppins", "sans-serif" !important;
}
.font-th {
  font-family: "Prompt", "sans-serif" !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
