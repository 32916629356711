$file-height: 10rem;
$file-width: 8rem;

.file-upload__item {
  position: relative;
  height: $file-height;
  width: $file-width;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.file-upload__item-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 22px !important;
  color: rgba(0, 0, 0, 0.54);
  padding: 0px;
}
.file-upload__item-loading {
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.file-upload__item-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.file-upload__item-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  flex-direction: row;
}
