@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --primary-color: #2196f3;
  --primary-color-hover: rgba(4, 27, 27, 0.9);
  --body-font-family: "Poppins", "sans-serif";
}

.arrow-up {
  &:after {
    @apply h-0 w-0 border-x-[6px] border-x-transparent border-b-[8px] inline-block absolute border-b-blue-100;
    content: "";
    top: -8px;
    left: 8px;
  }
}

.arrow-up-white {
  &:after {
    @apply h-0 w-0 border-x-[6px] border-x-transparent border-b-[8px] inline-block absolute border-b-white;
    content: "";
    top: -8px;
    left: 8px;
  }
}

.left-menu-item {
  @apply text-gray-900 !important;
  &.active {
    @apply text-primary bg-blue-100 !important;
  }
  &.active span, &:hover span{
    @apply text-primary !important;
  }
}