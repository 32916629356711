// $height-A5: calc(8.5in);
// $width-A5: calc(11in);
$height-A5: 297mm;
$width-A5: 182mm;

// $height-A5: calc(210mm);
// $width-A5: calc(148mm);

// $height-A5: 8.27in;
// $width-A5: 5.83in;

$height-footer: 100px;

.label-print__container {
  width: $height-A5;
  height: $width-A5;
  background-color: white;
  display: flex;
  // padding: 10px;
  //   border: 1px solid #eeeeee;
  //   transform: rotate(-90deg);
}

.label-print__column-header {
  display: flex;
  align-items: center;
  height: auto;
}
.label-print__image-container {
  position: relative;
  padding: 10px;
  // height: $width-A5;
  width: calc($height-A5 / 2) - 20px;
  display: flex;
  flex-direction: column;

  > .label-print__image-product {
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
    > img {
      object-fit: cover;
      height: calc(185mm / 2);
      border-radius: 10px;
      width: 100%;
    }
  }
}
.label-print__row-data {
  display: flex;
  border-bottom: 1px dashed #eeeeee;
  padding: 15px 0px;
  h4 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    font-family: var(--appFont);
    font-size: 25px !important;
  }
}

.label-print__content {
  > div:nth-last-child(2) {
    border-bottom: none !important;
  }
}

.label-print__row-data-small {
  display: flex;
  border-bottom: 1px dashed #eeeeee;
  padding: 2px 0px;
  // word-break: break-all;
  text {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    // font-family: var(--appFont);
    font-size: 11px !important;
    line-height: 16px !important;
  }
}

.label-print__sub-content {
  background-color: #f2f2f2 !important;
  padding: 10px 12px;
  margin-top: 10px;
  h5 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    font-family: var(--appFont);
    font-size: 20px !important;
  }

  > div {
    border-bottom: 1px dashed #c4c4c4;
    padding: 16px 0px;
  }
  div:last-child {
    border-bottom: none !important;
  }
}

.label-print__info-container {
  // width: 50%;
  width: calc($height-A5 / 2);
  // height: $width-A5;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0px 16px;
}

.label-print__info-main {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.label-print__channel-image {
  object-fit: contain;
  // max-height: 100px;
  width: 100px;
}

.label-print__column-footer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: auto;

  > img {
    max-height: 80px;
  }
}

.label-print_column-footer-model {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  font-family: var(--appFont);
  font-size: 24px !important;
  text-transform: uppercase;
  font-weight: bold !important;
}

.label-print_column-footer-attr {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  font-family: var(--appFont);
  font-size: 24px !important;
  text-transform: uppercase;
}

.label-print__day-label {
  background-color: rgba(239, 239, 239, 0.6) !important;
  padding: 5px 10px;
  position: absolute;
  top: 10px;
  right: 20px;
}

.printClass {
  display: none;
}

.label-print__info-product-name {
  font-size: 24px;
  font-weight: 700;
}

.label-print-full__info-product-name {
  font-weight: 700;
  font-size: 22px;
}

@media print {
  html,
  body {
    height: auto;
    overflow: visible;
  }

  #app-layout__content::-webkit-scrollbar {
    display: none;
  }

  .noPrintClass {
    display: none;
  }

  .printClass {
    display: flex;
  }

  .printClass:last-child {
    page-break-after: auto;
  }

  .label-print__container {
    // height: 100vh;
    // overflow: hidden;
    // page-break-after: always;
    width: 100vw;
  }

  body.sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .right-side,
  .sidebar-mini.sidebar-collapse .main-footer {
    margin-left: 0px !important;
  }

  .content {
    padding: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.pagebreak {
  page-break-after: always;
}
