.font-family-en {
  font-family: "Poppins", Sans-serif;
}

.font-family-th {
  font-family: "Prompt", Sans-serif;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-between-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.image-show-wrapper {
  > .overlay {
    visibility: hidden;
  }
}

.image-show-wrapper:hover {
  > .overlay {
    background-color: rgba(0, 0, 0, 0.2);
    visibility: visible;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
}

.bg-primary-25 {
  background-color: rgba(33, 150, 243, 0.25);
}

.bg-error {
  background-color: #f44336;
}

.w-min-content {
  width: min-content;
}

.app-layout__page-header {
  & > div:first-child {
    padding: 0.5rem 1.5rem;
    border-bottom: 1px solid $border-color;
    align-items: center;
    margin-bottom: 0 !important;
  }
  & > div:nth-child(2) {
    border-bottom: 1px solid $border-color;
    padding-right: 1.5rem;
    .d-input-search {
      border: 0 !important;
    }
    .d-button {
      background-color: #fff !important;
      color: $primary-color;
    }
  }
  .h4 {
    font-size: 14px;
    color: $primary-color;
  }
  .d-button {
    height: 34px;
    font-size: 12px !important;
    i {
      margin-left: 0 !important;
    }
  }
}

.border {
  border: 1px solid #ececec;
}

.bg-primary-25 {
  background-color: #edf7ff;
}

.text-hint {
  color: #a7a7a7;
}

.auth-otp-progress {
  position: absolute !important;
  bottom: -15px;
  left: 0px;
  right: 0px;

  .ant-progress-inner {
    border-radius: 0px !important;
  }

  .ant-progress-bg {
    border-radius: 0px !important;
    background-color: $primary-color;
  }
}

.cart-rule__condition-select {
  .ant-select-selector {
    background: $primary-color !important;
    color: white !important;
    text-decoration: underline;
    font-weight: bold !important;
  }

  .ant-select-selection-item {
    font-weight: bold !important;
    font-size: 16px !important;
  }
  .ant-select-clear,
  .ant-select-arrow {
    display: none !important;
  }
  .d-select__select-standard {
    border-bottom: none !important;
  }
}

.cart-rule__condition-apply-type {
  .ant-select-selector {
    background: #000 !important;
  }
}

.cart-rule__condition-input-text {
  .d-input-text__input {
    background-color: $primary-color;
    color: white !important;
  }
}

.d-button__standard-primary {
  .ant-spin-dot-item {
    background-color: white !important;
  }
}


.text-max-line-2 {
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}