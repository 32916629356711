$compact-menu-width: 50px;
$expand-menu-width: 300px;

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
html {
  font-size: 16px;
}
body {
  overflow-y: hidden !important;
  font-size: 0.875rem;
}

@mixin expanding-avatar {
  min-width: 85px !important;
  width: 85px !important;
  height: 85px;
  object-fit: cover;
  margin: 10px;
  border-radius: 15px;
  div.text-white {
    font-size: 40px;
    font-weight: 500;
  }
}

@mixin compact-avatar {
  min-width: 40px !important;
  width: 40px !important;
  height: 40px;
  border-radius: 5px;
  div.text-white {
    font-size: 25px;
    font-weight: 500;
  }
}

@mixin user-expanding-info-view {
  .user-info-avatar {
    @include expanding-avatar();
  }
  text {
    display: inline-block !important;
  }
}

@mixin user-compact-info-view {
  display: flex;
  padding: 10px 0px 0px 0px;
  margin-bottom: 0px;
  .user-info-avatar {
    @include compact-avatar();
  }
  text {
    display: none !important;
  }
}

.app-layout__menu-list {
  height: calc(100vh - 220px);
  overflow: auto;
  .app-layout__menu-list-item {
    i {
      color: #fff !important;
    }
  }
}

@mixin menu-compact-view {
  min-width: $compact-menu-width;
  max-width: $compact-menu-width;
  .app-layout__sidebar-header {
    max-width: $compact-menu-width;
    min-width: $compact-menu-width;
    padding: 10px 5px;
    img {
      width: $compact-menu-width - 10px !important;
      height: auto !important;
    }
    .app-layout__logo {
      width: $compact-menu-width - 10px !important;
      height: 40px;
      background-image: url("../logos/Sale-infinity_logo-W200.png");
    }
  }
  .app-layout__sidebar-user-info {
    .user-info {
      display: none;
    }
  }
  .search-menu {
    display: none;
  }
  .user-otp-opener {
    width: 36px;
  }
  .app-layout__menu-list {
    max-width: $compact-menu-width;
    min-width: $compact-menu-width;
    display: block;
    .app-layout__menu-list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      .app-layout__menu-list-title,
      .app-layout__menu-list-icon-arrow {
        display: none;
      }
    }
  }
  .app-layout__sidebar-user-info {
    @include user-compact-info-view();
  }
  .app-layout__sub-menu-list {
    display: none;
  }
  .app-layout__sidebar-button-toggle {
    width: $compact-menu-width;
    .app-layout__sidebar-button-toggle-text {
      display: none;
    }
  }
}

@mixin menu-expanding-view {
  max-width: $expand-menu-width;
  min-width: $expand-menu-width;
  .app-layout__sidebar-header {
    max-width: $expand-menu-width;
    min-width: $expand-menu-width;
    .app-layout__logo {
      width: $expand-menu-width - 10px !important;
      height: 40px;
      background-image: url("../logos/Sale-infinity_logo-W700.png");
    }
  }
  .app-layout__menu-list {
    max-width: $expand-menu-width;
    min-width: $expand-menu-width;
    .app-layout__menu-list-item {
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin-top: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      i {
        color: #fff !important;
      }

      :hover {
        cursor: pointer;
      }
    }

    .app-layout__menu-list-item-inner {
      display: flex;
      width: 100%;
      align-items: center;
      cursor: pointer;
    }

    .app-layout__menu-list-item-active {
      background: rgba($color: #ffffff, $alpha: 0.3);

      i {
        color: $light-color;
      }

      .app-layout__menu-list-title {
        color: $light-color;
      }
    }

    .app-layout__sub-menu-item-active {
      .app-layout__menu-list-title {
        color: $light-color !important;
      }
    }

    .app-layout__menu-list-title {
      color: $light-opposite-muted-color;
      margin-left: 20px;
      flex: 1;
    }

    .app-layout__sub-menu-list {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .app-layout__sub-menu-item {
      padding: 10px;
      color: $light-opposite-muted-color;
      i {
        color: $dark-color;
      }
    }
    .app-layout__sub-menu-item {
      .app-layout__menu-list-title {
        display: flex !important;
        width: 100% !important;
      }
      text-align: start !important;
    }
  }
  .app-layout__sidebar-user-info {
    @include user-expanding-info-view();
  }
  .app-layout__menu-list-title,
  .app-layout__menu-list-icon-arrow {
    display: inline !important;
    text-align: start !important;
  }
}

#app-layout__content {
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  width: calc(100vw - $compact-menu-width);
}

.app-layout__wrapper {
  background-color: whitesmoke !important;
  .app-layout__navbar {
    padding: 8px 16px 8px 4px;
  }
  #app-layout__sidebar {
    max-width: $expand-menu-width;
    min-width: $expand-menu-width;
    .app-layout__sidebar-header {
      max-width: $expand-menu-width;
      min-width: $expand-menu-width;
      padding: 10px 20px;
      .app-layout__logo {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: $expand-menu-width - 10px !important;
        height: 40px;
        background-image: url("../logos/Sale-infinity_logo-W700.png");
      }
    }
    .app-layout__sidebar-user-info {
      .user-info-avatar {
        @include expanding-avatar();
      }
    }
    .app-layout__menu-list-item-inner:hover,
    .app-layout__sub-menu-item:hover {
      background-color: rgba($color: #fff, $alpha: 0.2);
    }
  }
  #app-layout__content {
    height: 100% !important;
    overflow-y: scroll !important;
    background-color: transparent !important;
    width: calc(100vw - $expand-menu-width) !important;
  }
  .app-layout__menu-list-item-sub-active {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.app-layout__wrapper {
  #app-layout__sidebar.full-screen {
    & + #app-layout__content {
      margin-left: 0;
    }
  }
  #app-layout__sidebar.active {
    position: absolute;
    left: 0;
    min-width: $compact-menu-width;
    z-index: 1000;
    height: 100%;
    @include menu-compact-view();
  }
  #app-layout__content.active {
    background-color: transparent !important;
    width: calc(100vw - $compact-menu-width) !important;
    margin-left: $compact-menu-width;
    @media (max-width: 768px) {
      width: 100vw !important;
    }
  }
  #app-layout__sidebar.mouse-enter {
    .app-layout__sidebar-user-info {
      .user-info {
        display: block;
      }
    }
    .search-menu {
      display: block;
    }
    .user-otp-opener {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .user-otp-opener {
    padding-left: 10px;
    padding-right: 10px;
    img {
      filter: brightness(100);
    }
  }
  @media (max-width: 768px) {
    #app-layout__content.active {
      margin-left: 0;
    }
    #app-layout__sidebar.active {
      max-width: 0;
      overflow: hidden !important;
    }
  }
}

.app-layout__wrapper {
  #app-layout__sidebar:hover {
    @include menu-expanding-view();
  }
}

.app-layout__wrapper {
  .mouse-enter#app-layout__content.active {
    background-color: transparent !important;
    width: calc(100vw - $compact-menu-width) !important;
  }
  .mouse-enter#app-layout__content {
    background-color: transparent !important;
    width: calc(100vw - $expand-menu-width) !important;
  }
}

.info-table {
  .ant-table-body {
    padding-bottom: 60px;
  }
}

.pswp img {
  object-fit: contain;
}

.d-input-search__outline,
.d-input-text__input-container-outline,
.d-select__select-outline {
  border: 1px solid #d9d9d9 !important;
}

.show-scroll-bar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.react-images__view--isModal img {
  margin: auto;
}

@media (max-width: 768px) {
  .d-drawer__container {
    .d-drawer__close-button {
      right: 0;
      left: auto;
    }
  }
  .ant-drawer-content-wrapper {
    max-width: 100%;
  }
  .tableItemPickup {
    &.d-table-awesome-component .ant-table-tbody > tr > td {
      padding-left: 50% !important;
    }
  }
  .dropdown-language {
    .d-dropdown-menu__item-main-view {
      .d-dropdown-menu__item-label {
        display: none;
      }
    }
    .d-dropdown-menu__item-main-view {
      min-width: auto;
    }
  }
  .app-layout__nav-bar-content {
    padding-right: 0;
  }
  .height-mobile-auto {
    height: auto !important;
  }
  #app-layout__sidebar {
    &.active {
      .switch-menu {
        min-width: 0 !important;
        max-width: 0 !important;
        width: 0;
      }
    }
  }
  .header-order {
    .header-table__custom-view-container {
      min-width: 140px;
      max-width: 140px;
    }
  }
}

body > div:last-child::-webkit-scrollbar {
  visibility: hidden;
}

.custom-scrollbar *::-webkit-scrollbar {
  visibility: hidden;
}

.ant-table-body {
  overflow: auto !important;
}

.webphone-widget {
  & > div {
    z-index: 1001;
  }
  overflow: auto !important;
}

.order-table {
  .ant-spin {
    display: none !important;
  }
}

.d-view-label-status {
  border-radius: 0 !important;
}

.rich-text__editor {
  min-height: 20rem;
}
