/******************************************PRINT*********************************************/

@page {
  size: auto;
  margin: 10px;
}

.print-container {
  margin: 0.2in;
}

.print-container .cardContainer {
  background-color: white;
  margin: 0.625em 0 0.625em 0;
  padding: 0;
  /*padding: 1.5625em;*/
  box-shadow: -1px -7px 83px -19px white;
}

.print-container .cardTitleText {
  font-family: var(--appFont);
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  color: #000000;
}

.print-container .subTitle1 {
  font-family: var(--appFont);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.print-container .subTitle2 {
  font-family: var(--appFont);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #919191;
}

.print-container .body1 {
  font-family: var(--appFont);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.print-container .body2 {
  font-family: var(--appFont);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(162, 162, 162, 0.87);
}

.print-container .captionText {
  font-family: var(--appFont);
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.87);
}

.print-container .modalTitle {
  font-family: var(--appFont);
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 1rem;
}

.print-container .bodyModalText {
  font-family: var(--appFont);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #919191;
  margin: 0.5rem 0.5rem;
}

.print-container .tableTitle {
  font-family: var(--appFont);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
}

$text-print-color: #4f4f4f;
@mixin text-default {
  font-family: var(--appFont);
  font-style: normal;
  font-weight: normal;
  font-style: normal;
  color: #474747;
  font-size: 14px;
  line-height: 16px;
}

.info-item-content-print-container {
  display: flex;
  margin: 5px 0 0 0;
  > .info-item-content-print-title {
    @include text-default();
    font-weight: bold;
    margin-right: 8px;
  }
  > .info-item-content-print-content {
    @include text-default();
  }
}

@media print {
  .noPrintClass {
    display: none;
    height: 0 !important;
  }

  div[class^="easy-print-NoPrint"] {
    height: 0;
    display: none;
  }

  .customProductExpandRowContain {
    top: -5px;
  }

  .app-layout__wrapper {
    background-color: #fff !important;
    * {
      color: #000 !important;
    }
  }

  .printClass {
    display: flex;
  }

  .label-print__container {
    // max-height: 100vh !important;
    overflow: hidden;
    page-break-after: always;
    height: 100vh !important;
  }

  body.sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .right-side,
  .sidebar-mini.sidebar-collapse .main-footer {
    margin-left: 0px !important;
  }

  .content {
    padding: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  #app-layout__sidebar,
  .app-layout__navbar,
  .fullscreen-button,
  .webphone-widget {
    display: none;
  }
  .main-content {
    overflow: auto !important;
    height: auto !important;
    padding: 0 !important;
  }
  .app-layout__wrapper {
    height: auto !important;
    #app-layout__content {
      height: auto !important;
      margin-left: 0 !important;
      width: 100% !important;
      &.active {
        width: 100% !important;
      }
    }
  }
  .orderDetailTableTitle {
    font-size: 0.875rem;
  }
  .block-remark-arrow {
    display: none;
  }
  .attributeListContainer {
    div {
      border: none;
    }
  }
  .ant-table-expand-icon-col {
    display: none;
  }
  .tableFooter {
    td {
      padding: 5px 5px;
    }
  }
  .ant-table-thead,
  .ant-table-tbody {
    font-size: 12px !important;
    * {
      font-size: 12px !important;
    }
  }
  tr.ant-table-expanded-row {
    top: -2px;
  }
  .order-products__item-name {
    .order-products__item-name__details {
      flex-direction: row !important;
      .item-details {
        margin-left: 10px !important;
      }
    }
  }
}

.print-order-customsize-container {
  .company-name {
    @include text-default();
    color: $text-print-color !important;
    font-size: 17px;
  }
  background-color: #e8e8e8;
  &-inforItem {
    &-text {
      @include text-default();
    }
  }
  .MuiIconButton-colorSecondary {
    padding: 2px 9px !important;
  }
  .companyAndOrderInfoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  #totalOrderCustomsize {
    margin-left: 25%;
  }
  .totalCustomSizeOrderCell {
    margin-right: 50%;
    background-color: greenyellow;
  }
}

.companyNameText-ReceiptPrint {
  @include text-default();
  color: #4f4f4f;
  font-size: 19px;
  line-height: 26px;
  /* identical to box height */

  text-transform: uppercase;
  margin-top: 17px;
}
.receiptText-receptOrderPrint {
  @include text-default();
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  /* identical to box height */

  text-align: right !important;

  /* Gray 1 */

  color: #333333;
}

.orderPrintCustomSizeContainer {
  padding-top: 0px !important;
  margin-bottom: 12px;
  width: 100%;
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 6px !important;
  }
  .ant-table-small {
    border: 0px solid white !important;
  }
  border-bottom: 0px solid #fff !important;
  padding-bottom: 0px !important;
  .tableTitle {
    @include text-default();
    font-weight: bold;
    color: #000000;
  }
  .body1 {
    @include text-default();
    color: rgba(0, 0, 0, 0.87);
  }
  .body2 {
    @include text-default();
    color: rgba(162, 162, 162, 0.87);
  }
  .MuiTableCell-root {
    padding: 6px;
    border-bottom: 0.5px dashed #c4c4c4;
  }
  .productImageItem {
    height: 25px;
    width: 35px;
    object-fit: cover;
    border-radius: 0px;
    margin-right: 15px;
  }
  .ant-table-thead {
    @include text-default();
    font-weight: bold;
    color: #000000;
  }
  .lastCellCustomsizeProduct {
    width: 10rem;
  }
}

.print-order-customsize-header-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  border-bottom: 0.5px dashed #c4c4c4;
  padding-bottom: 12px;
  padding-top: 12px;
  justify-content: space-between;
}

.print-order-customsize-header-container-special {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  border-bottom: 0.5px dashed #c4c4c4;
  padding-bottom: 12px;
  padding-top: 12px;
  // justify-content: space-between;
}

.receiptInfo-customSizeOrderPrint {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  > .info-item-content-print-container {
    justify-content: flex-end;
  }
}
.titleShippingBillingText-printOrderReceipt {
  @include text-default();
  font-weight: bold !important;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 160% */

  color: rgba(0, 0, 0, 0.87);
}

.contentShippingBillingText {
  @include text-default();
}

.conditionTitle-printOrderReceipt {
  @include text-default();
  font-weight: bold;
}

.print-order-condition-header-container {
  display: flex;
  flex-direction: column;
  // padding-top: 8px;
  border-bottom: 0.5px dashed #c4c4c4;
}

.print-customsize-order-signature-header-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px !important;
  height: auto;
  .print-order-signature-paidByContainer {
    align-items: flex-end;
  }
  .paidSignatureImage {
    height: auto;
    width: 100px;
    object-fit: contain;
    align-self: center;
    border-bottom: 0.5px dashed #c4c4c4;
    // transform: rotate(-90deg);
    // margin-left: 40px;
    // margin-bottom: 0px;
    // background-color: red;
    // display: none;
  }
  .signatureInput-print-order-signature {
    border-bottom: 0.5px dashed #c4c4c4;
    width: 100px;
  }
}

.activityItemPrint {
  margin-top: 2rem;
  .commentActivityText {
    @include text-default();
  }
}
.company-container {
  margin: 0 auto auto 0;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 50%;
  max-width: 50%;
  min-width: 50%;
  /*background-color: #fa8c16;*/
}

.company-logo {
  height: 60px;
  /* max-width: 16.7em; */
  object-fit: contain;
}

.company-name {
  font-weight: bold;
  font-size: 1.75rem;
  font-family: "Prompt", Sans-serif;
  text-transform: uppercase;
  /* color: white; */
  font-style: normal;
  color: var(--theme-color);
}

.company-desc {
  font-size: 1em;
  line-height: 1.5em;
  /* color: white; */
  font-family: "Prompt", Sans-serif;
  /* height: 1.5em; */
  margin-top: 1em;
}
.print-order-receipt-container {
  .company-name {
    @include text-default();
    color: $text-print-color !important;
    font-size: 19px;
  }
}

.companyNameText-ReceiptPrint {
  @include text-default();
  color: #4f4f4f;
  font-size: 19px;
  line-height: 26px;
  /* identical to box height */

  text-transform: uppercase;
  margin-top: 17px;
}
.receiptText-receptOrderPrint {
  @include text-default();
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  /* identical to box height */

  text-align: right !important;

  /* Gray 1 */

  color: #333333;
}

.orderDetailTablePrintReceiptContainer {
  padding-top: 0px !important;
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 4px 6px !important;
  }
  .ant-table-small {
    border: 0px solid white !important;
  }
  border-bottom: 0px solid #fff !important;
  padding-bottom: 0px !important;
  .tablePrintContainer {
    .ant-table-row-expand-icon-cell {
      padding: 0px !important;
      width: 0px !important;
      // visibility: hidden;
      display: none;

      > .ant-table-row-expand-icon {
        display: none;
      }
    }
    .ant-table-row-level-2,
    .ant-table-row-level-1 {
      .productNameViewContainer {
        padding-left: 25px;
      }
    }
  }
}

.print-order-receipt-header-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  border-bottom: 0.5px dashed #c4c4c4;
  padding-bottom: 12px;
  padding-top: 12px;
  justify-content: space-between;

  .tableTitle {
    @include text-default();
    font-weight: bold;
    color: #000000;
  }
  .body1 {
    @include text-default();
    color: rgba(0, 0, 0, 0.87);
  }
  .body2 {
    @include text-default();
    color: rgba(162, 162, 162, 0.87);
  }
  .MuiTableCell-root {
    padding: 6px;
    border-bottom: 0.5px dashed #c4c4c4;
  }
  .productImageItem {
    height: 25px;
    width: 35px;
    object-fit: cover;
    border-radius: 0px;
    margin-right: 15px;
  }
  .ant-table-thead {
    @include text-default();
    font-weight: bold;
    color: #000000;
  }
}

.receiptInfo-receiptOrderPrint {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
  max-width: 50%;
  min-width: 50%;
  > .info-item-content-print-container {
    justify-content: flex-end;
  }
}
.titleShippingBillingText-printOrderReceipt {
  @include text-default();
  font-weight: bold !important;
  /* identical to box height, or 160% */

  color: rgba(0, 0, 0, 0.87);
}

.contentShippingBillingText {
  @include text-default();
}

.conditionTitle-printOrderReceipt {
  @include text-default();
  font-weight: bold;
}
.conditionContent-printOrderReceipt {
  @include text-default();
  line-height: 18px;
  margin-top: 1rem;
  > p {
    @include text-default();
    font-weight: bold !important;
  }
  > ol > li {
    @include text-default();
    font-size: 12px !important;
  }
  > ol {
    padding-inline-start: 14px !important;
    list-style: auto;
  }
}

.print-order-condition-header-container {
  display: flex;
  flex-direction: column;
  // padding-top: 8px;
  border-bottom: 0.5px dashed #c4c4c4;
}

.print-order-signature-header-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px !important;
  height: auto;
  .print-order-signature-paidByContainer {
    align-items: flex-end;
  }
  .paidSignatureImage {
    height: auto;
    width: 100px;
    object-fit: contain;
    align-self: center;
    border-bottom: 0.5px dashed #c4c4c4;
    // transform: rotate(-90deg);
    // margin-left: 40px;
    // margin-bottom: 0px;
    // background-color: red;
    // display: none;
  }
  .signatureInput-print-order-signature {
    border-bottom: 0.5px dashed #c4c4c4;
    width: 100px;
  }
}

.activityItemPrint {
  margin-top: 2rem;
  .commentActivityText {
    @include text-default();
  }
}

.print-order-pickup-container {
  .company-name {
    @include text-default();
    color: $text-print-color !important;
    font-size: 19px;
    line-height: 26px;
  }

  h3 {
    margin-bottom: 0 !important;
    font-family: var(--appFont) !important;
    font-weight: bolder;
  }
  .companyNameText-ReceiptPrint {
    @include text-default();
    color: #4f4f4f;
    font-size: 19px;
    line-height: 26px;
    /* identical to box height */

    text-transform: uppercase;
    margin-top: 17px;
  }

  .receiptText-pickupOrderPrint {
    @include text-default();
    font-weight: bolder !important;
    font-size: 26px;
    line-height: 31px;
    /* identical to box height */

    text-align: right !important;

    /* Gray 1 */

    color: #333333;
  }

  .orderDetailTablePrintOrderPickupContainer {
    padding-top: 2rem !important;
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 6px !important;
    }
    .ant-table-small {
      border: 0px solid white !important;
    }
    .cardTitleTextPickupItem {
      @include text-default();
      font-weight: bold !important;
      margin-top: 2rem;
    }
  }

  .print-order-pickup-header-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    border-bottom: 0.5px dashed #c4c4c4;
    padding-bottom: 9px;
    padding-top: 9px;
    justify-content: space-between;

    .tableTitle {
      @include text-default();
      font-weight: bold !important;
      color: #000000;
    }
    .body1 {
      @include text-default();
      color: rgba(0, 0, 0, 0.87);
    }
    .body2 {
      @include text-default();
      color: rgba(162, 162, 162, 0.87);
    }
    .MuiTableCell-root {
      padding: 6px;
      border-bottom: 0.5px dashed #c4c4c4;
    }
    .productImageItem {
      height: 25px;
      width: 35px;
      object-fit: cover;
      border-radius: 0px;
      margin-right: 15px;
    }
    .ant-table-thead {
      @include text-default();
      font-weight: bold;
      color: #000000;
    }
  }

  .receiptInfo-receiptOrderPrint {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    > .info-item-content-print-container {
      justify-content: flex-end;
    }
    &-header {
      @include text-default();
      font-size: larger !important;
      font-weight: bolder !important;
      line-height: 20px !important;
    }
  }

  .titleShippingBillingText-printOrderPickUp {
    @include text-default();
    font-weight: bolder !important;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0.5rem;
  }

  .contentShippingBillingText {
    @include text-default();
  }

  .conditionTitle-printOrderReceipt {
    @include text-default();
    font-weight: bold !important;
  }
  .conditionContent-printOrderReceipt {
    @include text-default();
    margin-top: 1rem;
    > p {
      @include text-default();
      font-weight: bold;
    }
    > ol > li {
      @include text-default();
      font-size: 12px !important;
    }
    > ol {
      padding-inline-start: 14px !important;
    }
  }

  .print-order-condition-header-container {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    border-bottom: 0.5px dashed #c4c4c4;
  }

  .print-order-signature-header-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px !important;
    height: auto;
    .print-order-signature-paidByContainer {
      align-items: flex-end;
    }
    .paidSignatureImage {
      height: auto;
      width: 100px;
      object-fit: contain;
      align-self: center;
      border-bottom: 0.5px dashed #c4c4c4;
      // transform: rotate(-90deg);
      // margin-left: 40px;
      // margin-bottom: 0px;
      // background-color: red;
      // display: none;
    }
    .signatureInput-print-order-signature {
      border-bottom: 0.5px dashed #c4c4c4;
      width: 100px;
    }
  }

  .activityItemPrint {
    margin-top: 2rem;
    .commentActivityText {
      @include text-default();
    }
  }
}

// cash deposit

$text-print-color: #4f4f4f;
@mixin text-default {
  font-family: var(--appFont);
  font-style: normal;
  font-weight: normal;
  font-style: normal;
  color: #474747;
  font-size: 14px;
  line-height: 19px;
}

@mixin print-border-bottom {
  border-bottom: 0.5px dashed #c4c4c4;
}

.print-deposit-container {
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 0px !important;
  }
  .ant-table-tbody > tr > td {
    padding: 8px 0px 8px 0px !important;
  }
  .browsePaymentContainer {
    padding: 0px;
    .totalAmountViewContainer {
      padding: 8px 0px 8px 0px;
    }
  }

  &-signatureContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4rem;
  }
  &-sectionContentView {
    @include print-border-bottom();
    margin: 1rem 0rem 1rem 0rem;
    padding-bottom: 1rem;

    .sectionTitleText {
      @include text-default();
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
    }

    .totalAmountViewText {
      @include text-default();
      font-weight: bold;
    }
  }

  > .companyOrderInfoContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  .ant-table-cell {
    @include text-default();
    font-weight: bold;
  }

  .nowrapCellTable {
    @include text-default();
  }

  .textContentPaymentDepositTable {
    @include text-default();
  }

  > .paymentConfirmationContainer {
    display: flex;
    flex-direction: column;
    > .paymentContentText {
      @include text-default();
    }
  }
}

.cashRepositRequestText {
  @include text-default();
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  /* identical to box height */

  text-align: right !important;

  /* Gray 1 */

  color: #333333;
}
